import {callOnAuthChange, getAuthorizedUserDisplayName, signInUser, signOutUser, signUpUser} from "./firebase";

const userNavBtn = document.getElementById("account-btn");
const userNameLabel = document.getElementById("accountUsernameLabel");
const signOutBtn = document.getElementById("signOutBtn");
const signInFormBtn = document.getElementById("signInSubmitBtn");
const signUpFormBtn = document.getElementById("signUpSubmitBtn");

signInFormBtn.addEventListener("click", onSignInSubmitted);
signUpFormBtn.addEventListener("click", onSignUpSubmitted);
signOutBtn.addEventListener("click", signOut);

function updateAccountModal(authorized) {
    if (authorized) {
        userNavBtn.setAttribute("data-bs-target", "#accountModal");
        userNameLabel.innerText = `Username: ${getAuthorizedUserDisplayName()}`;
    }
    else {
        userNavBtn.setAttribute("data-bs-target", "#signUpModal");
    }
}

function onSignInSubmitted(event) {
    const signInEmail = document.getElementById("signInEmailInput").value;
    const signInPassword = document.getElementById("signInPasswordInput").value;

    signInUser(signInEmail, signInPassword)
        .then((result) => {
            if (result) {
                document.location.reload();
            }
        })
        .catch((error) => {
            // show error message in modal
            // and clear inputs?
        });
}

function onSignUpSubmitted(event) {
    const signUpEmail = document.getElementById("signUpEmailInput").value;
    const signUpPassword = document.getElementById("signUpPasswordInput").value;
    const signUpDisplayName = document.getElementById("signUpDisplayNameInput").value;

    signUpUser(signUpEmail, signUpPassword, signUpDisplayName)
        .then((result) => {
            if (result) {
                document.location.reload();
            }
        })
        .catch((error) => {
            // show error message in modal
            // and clear inputs?
        });
}

function signOut() {
    signOutUser()
        .then(() => {
            document.location.reload();
        })
}

callOnAuthChange(updateAccountModal);
