// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "@firebase/functions";
import { getAuth, updateProfile, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, connectAuthEmulator } from "@firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCXJi69rhF94regWe94PJVfMDFWftqrmEs",
    authDomain: "braggle.firebaseapp.com",
    projectId: "braggle",
    storageBucket: "braggle.appspot.com",
    messagingSenderId: "684462905877",
    appId: "1:684462905877:web:ef6aa54c62654c9ebecc1c",
    measurementId: "G-HM3BE17H3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const functions = getFunctions(app);

export const functionsRoot = process.env.NODE_ENV === "development" ? "localhost:5001/braggle/us-central1/" : "us-central1-braggle.cloudfunctions.net/";

if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functions, "localhost", 5001);
}

let authorizedUser = null;
let authChangeCallbacks = [];

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("user signed in");
        authorizedUser = user;

        authChangeCallbacks.forEach((callback) => {
            callback(true, user);
        })

        // ...
    } else {
        // User is signed out
        // ...
        console.log("user signed out");

        authChangeCallbacks.forEach((callback) => {
            callback(false, null);
        })
    }
});

export function callOnAuthChange(callbackFunc) {
    authChangeCallbacks.push(callbackFunc);
}

export async function signInUser(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            authorizedUser = user;
            // ...

            return true;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error(errorMessage);

            return false;
        });
}

export async function signOutUser() {
    return signOut(auth)
        .then(() => {
            console.log("Successfully logged out");
            return true;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        });
}

export async function addScoreForAuthUser(gameDocName, gameScore, dateTime) {
    const addScoreFunc = httpsCallable(functions, "addScore");
    const funcData = {
        "submitTime": dateTime,
        "game": gameDocName,
        "score": gameScore
    };

    return addScoreFunc(funcData)
        .then((callRes) => {
            return callRes.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export async function editScoreForAuthUser(scoreDocId, newScore) {
    const editScoreFunc = httpsCallable(functions, "editScore");
    const funcData = {
        "id": scoreDocId,
        "newScore": newScore
    };

    return editScoreFunc(funcData)
        .then(() => { return true; })
        .catch((error) => {
            console.log(error.message);
            return false;
        })
}

export async function removeScoreForAuthUser(scoreDocId) {
    const removeScoreFunc = httpsCallable(functions, "removeScore");
    const funcData = {
        "id": scoreDocId
    };

    return removeScoreFunc(funcData)
        .then(() => { return true; })
        .catch((error) => {
            console.log(error.message);
            return false;
        })
}

export async function signUpUser(email, password, displayName) {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            authorizedUser = user;

            const addUserFunc = httpsCallable(functions, "addNewUser");
            return addUserFunc({"uid": user.uid, "displayName": displayName})
                .then(() => {
                    return updateProfile(user, {displayName: displayName})
                        .then(() => {
                            return true;
                        })
                        .catch((error) => {
                            console.log(error.message);
                            return false;
                        });
                })
                .catch((error) => {
                    console.log(error.message);
                    return false;
                });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error(errorMessage);

            return false;
        });
}

export async function getCurrentUsersScoresForDate(dateTime) {
    const getScoresFunc = httpsCallable(functions, "getScoresForDate");
    const funcData = {
        "timeStart": new Date(dateTime.setHours(0, 0, 0, 0)),
        "timeEnd": new Date(dateTime.setHours(23, 59, 59, 999))
    };

    return getScoresFunc(funcData)
        .then((scoreData) => {
            console.log(scoreData);
            return scoreData.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        })
}

export async function getCurrentUsersRooms() {
    const getRoomsFunc = httpsCallable(functions, "getUserRooms");

    return getRoomsFunc()
        .then((roomData) => {
            console.log(roomData);
            return roomData.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export function getAuthorizedUserDisplayName() {
    if (authorizedUser !== null) {
        return authorizedUser.displayName;
    }

    return "";
}

export async function addNewRoom(roomName) {
    const addRoomFunc = httpsCallable(functions, "createRoom");
    return addRoomFunc({"roomName": roomName})
        .then((newRoomData) => {
            console.log("Added new room");
            return newRoomData.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        })
}

export async function getRoomData(roomId) {
    const getRoomFunc = httpsCallable(functions, "getRoomData");
    return getRoomFunc({"roomId": roomId})
        .then((roomData) => {
            return roomData.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export async function getScoresForRoom(roomId, dateTime) {
    const getScoresFunc = httpsCallable(functions, "getScoresForRoom");
    const funcData = {
        "roomId": roomId,
        "timeStart": new Date(dateTime.setHours(0, 0, 0, 0)),
        "timeEnd": new Date(dateTime.setHours(23, 59, 59, 999))
    };
    return getScoresFunc(funcData)
        .then((scoreData) => {
            console.log(scoreData);
            return scoreData.data;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export async function joinRoom(roomId) {
    const joinRoomFunc = httpsCallable(functions, "joinRoom");
    const funcData = {
        "roomId": roomId
    };
    return joinRoomFunc(funcData)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        })
}

export async function leaveRoom(roomId) {
    const leaveRoomFunc = httpsCallable(functions, "leaveRoom");
    const funcData = {
        "roomId": roomId
    };

    return leaveRoomFunc(funcData)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        });
}

export async function deleteRoom(roomId) {
    const deleteRoomFunc = httpsCallable(functions, "deleteRoom");
    const funcData = {
        "roomId": roomId
    };

    return deleteRoomFunc(funcData)
        .then((didDelete) => {
            return didDelete.data;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        });
}

export async function isAuthUserAdmin(roomId) {
    const isAdminFunc = httpsCallable(functions, "isRoomAdmin");
    const funcData = {
        "roomId": roomId
    };

    return isAdminFunc(funcData)
        .then((result) => {return result.data;})
        .catch((error) => {
            console.log(error.message);
            return false;
        });
}
